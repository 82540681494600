<template>
	<div data-component="alert">
		<div data-element="wrapper">
			<icon
				data-element="info"
				icon="info"
				size="lg"
				colour="white"
			/>
			<p data-element="text">
				{{ text }}
			</p>
			<slot name="action" :close="close">
				<icon
					data-element="close"
					icon="cross"
					size="lg"
					colour="white"
					@click.native="close"
				/>
			</slot>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			text: {
				type: String,
				default: ''
			}
		},
		computed: {

		},
		methods: {
			close () {
				this.$emit('close');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='alert'] {
		[data-element='wrapper'] {
			padding: rem(14) rem(18);
			background-color: $c-brand-orange;
			border-radius: 8px;
			display: flex;
			align-items: center;
		}
		[data-element='text'] {
			color: $c-white;
			margin: 0;
			font-size: rem(14);
			font-weight: 700;
		}
		[data-component='icon'] {
			width: rem(28);
			height: auto;
			user-select: none;

			&[data-element='info'] {
				margin-right: rem(8);
			}

			&[data-element='close'] {
				margin: auto 0 auto auto;
				cursor: pointer;
			}
		}
	}

</style>
